import {gql} from '@apollo/client';
import graphClient from '@/api/graph';
import {RequirementBlock} from '@/degrees/types';
import {BlockType} from '@/degrees/common';
import * as fragments from '@/api/graphql/fragments';

export const GET_BLOCK_BY_TYPE_VALUE = gql`
query getRequirementBlocksByTypeValue($institution: String!, $blockType:String!, $blockValue:String!) {
    items:requirement_blocks(where:{
        institution:{
            _eq:$institution
        }
      	block_type:{
          _eq:$blockType
        }
      	block_value:{
          _eq:$blockValue
        }
        _or: [
          	{active_valid: {_eq:true}},
          	{allow_expired: {_eq:true}}
        ]
        
    }, order_by: {title:asc}) {
        ...requirementBlock
    }
}
${fragments.requirementBlock}
`;

export const GET_BLOCK_BY_ID = gql`
query getRequirementBlocksById($institution: String!, $id:String!, $onlyActiveValid:Boolean!) {
    items:requirement_blocks(where:{
        institution:{
            _eq:$institution
        }
      	requirement_id:{
          _eq:$id
        }
        _or: [
          	{active_valid: {_eq:$onlyActiveValid}},
          	{allow_expired: {_eq:true}}
        ]
        
    }, order_by: {title:asc}) {
        ...requirementBlock
    }
}
${fragments.requirementBlock}
`;

interface Props {
    institution: string;
    blockType: BlockType,
    blockValue: string;
}

export const getRequirementBlock = async (props: Props): Promise<RequirementBlock> => {
    const results = await graphClient.query({
        query: GET_BLOCK_BY_TYPE_VALUE,
        variables: props,
        fetchPolicy: 'network-only'
    });

    return results.data.items[0];
};

interface ByIdProps {
    institution: string;
    id: string;
}

export const getRequirementBlockById = async (props: ByIdProps, onlyActiveValid = true): Promise<RequirementBlock> => {
    const results = await graphClient.query({
        query: GET_BLOCK_BY_ID,
        variables: {
            ...props,
            onlyActiveValid
        },
        fetchPolicy: 'network-only'
    });

    return results.data.items[0];
};


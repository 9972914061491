import { LoginResponse } from '@/api/types';
import config from '@/config';
import http from '@/api/http';

export default async (username: string, password = ''): Promise<LoginResponse> => {
    const endpoint = `${config.apiUrl}/login`;
    const { data } = await http.post(endpoint, { username, password });
    console.log('accountLogin data: ', data);
    return data;
};

import { gql } from '@apollo/client';
import * as fragments from '@/api/graphql/fragments';

export default gql`
query getStudentInfo($studentId:String!) {
    items: custom_names(where:{
      emplid: {
        _eq: $studentId
      } 
    } limit:1) {
        studentId: emplid,
        cohort: cohort
        name: name_display,
        
        academicPlans: academic_plans {
            effdt
            academicPlan: acad_plan
            declaredDate: declare_dt
            reqTerm: req_term
            completionTerm: completion_term
            plan {
                transcriptDescription: trnscr_descr
                institution
            }
            studentCareerNum: stdnt_car_nbr
        }
        
        academicSubplans: academic_subplans {
            effdt
            academicSubplan: acad_sub_plan
            academicPlan: acad_plan
            declaredDate: declare_dt
            reqTerm: req_term
        }
        
        academicPrograms: academic_programs {
            effdt
            institution
            academicProgram: acad_prog
            status: prog_status
            action: prog_action
            actionDate: action_dt
            reason: prog_reason
            admitTerm: admit_term
            completionTerm: completion_term
            studentCareerNum: stdnt_car_nbr
        }
        
        enrollments: enrollment_record(
            order_by: [{strm: desc}, {institution: asc}, {class_nbr: asc}]
        ) {
            ...enrollments
        }  

        isDev: is_dev,
        
        milestones {
            institution,
            milestoneName: milestone,
            description: descrformal,
            level
        }
        groups {
            institution,
            groupName: group_name,
            description: descr
        }
        placements {
            testId: test_id,
            testComponent: test_component,
            date: test_dt,
            score,
            letterScore: score_ltr
        }
    }
}
${fragments.enrollments}
`;

import React from 'react';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

interface Props {
    completed: boolean;
}

export default ({ completed }: Props) => {
    if (completed) {
        return <FontAwesomeIcon css={tw`mr-1 text-green-500`} icon={faCheckSquare} />;
    }
    return <FontAwesomeIcon css={tw`mr-1 text-red-500`} icon={faSquare} />;
};

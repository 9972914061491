import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { chartColors } from '@/components/dashboard/chartUtils';

export const DashboardContainerStyle = styled.div`
    // ${tw`flex flex-row`}    
`;

export const ResultsFilterBarContainerStyle = styled.div`
    max-width: 1500px;
    min-width: 700px;
    ${tw`w-full mb-8 px-4 flex flex-row justify-between`}
`;

export const BaseButtonStyle = styled.button`
    background-color: rgb(247,247,247);
    color: rgb(98,98,98);
    border: 1px solid rgb(98,98,98);
    ${tw`rounded cursor-pointer text-lg px-2 py-1 hover:opacity-75 shadow-sm`}
    ${props => props.disabled ? tw`cursor-not-allowed hover:opacity-75 opacity-75` : ''}
`;
export const SubmitFilterButtonStyle = styled(BaseButtonStyle)`
    
`;

export const RemoveQueryButtonStyle = styled.a`
    ${tw`cursor-pointer hover:opacity-75 text-xl text-base`}
`;

export const AddQueryButtonStyle = styled.a`
    ${tw`cursor-pointer hover:opacity-75 text-xl`}
    // color: ${chartColors[0].normal}
    ${tw`text-blue-500`}
`;

export const GalleryGridStyle = styled.div`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, 33%);
    grid-auto-rows: 350px;
    max-width: 1500px;
    min-width: 700px;

    @media(max-width: 1300px) {
        grid-template-columns: repeat(auto-fill, 50%);
    }
`;

interface GalleryGridItemProps {
    rowSpan: 1 | 2 | 3 | 4;
    colSpan: 1 | 2 | 3 | 4;
}

export const GalleryGridItemStyle = styled.div<GalleryGridItemProps>`
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
    border: 8px solid rgba(0, 0, 0, 0);
    grid-row: span ${props => props.rowSpan};
    grid-column: span ${props => props.colSpan};
`;

export const CardStyle = styled.div`
    ${tw`bg-white rounded overflow-hidden flex-1`}
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
`;

export const CardHeaderStyle = styled.h3`
    ${tw`mb-1 font-normal text-xl text-gray-700 rounded-t bg-gray-100 py-1 px-4`}
`;

export const CardBodyStyle = styled.div`
    ${tw`bg-white rounded-b overflow-hidden px-4 pb-4 pt-2`}
`;

export const StudentInfoContainerStyle = styled.div`
    ${tw`px-4 py-3`}

    min-width: 400px;

    h3 {
        ${tw`text-dark`}
    }
`;

export const AccountTagStyle = styled.span`
    ${tw`px-1 text-xs text-white bg-yellow-500 rounded-full`}
`;

export const TableContainerStyle = styled.div`
    overflow: auto;
`;

interface StatsTableStyleProps {
    sortable?: boolean;
}
export const StatsTableStyle = styled.table<StatsTableStyleProps>`
    ${tw`w-full`}
    thead {
        tr {
            ${tw`border-b border-gray-400`}
        }
        th:first-child {
            text-align: left;
        }
        th {
            position: sticky;
            top: 0;
            background-color: white;
            ${props => props.sortable ? 'padding-right: 10px;' : ''}

            span, p {
                ${tw`relative font-normal text-sm text-gray-800 inline-block select-none`}

                svg {
                    position: absolute;
                    right: -2px;
                    top: 50%;
                    transform: translate(100%, -50%);
                }
            }
        }
    }
    tbody {
        tr:not(:last-child) {
            ${tw`border-b border-gray-200`}
        }
        
        td {
            ${tw`py-1`}
            ${props => props.sortable ? 'padding-right: 10px;' : ''}
            p {
                ${tw`text-base`}
            }
        }
    }
`;

export const ResultsTableStyle = styled.table`
    position: relative;
    border: 1px solid #ccc;
    
    th,td {
        border: 1px solid #ccc;
    }
    th {
        ${tw`whitespace-no-wrap font-medium px-2 py-1 text-left`}
          background: white;
          
          a {
            ${tw`cursor-pointer`}
            
            svg {
                ${tw`ml-2`}
            }
          }
    }
    
    // td:nth-child(2) {
    //     max-width: 340px;
    //     text-overflow: ellipsis;
    //     overflow: hidden;
    //     white-space: nowrap;
    // }
    td {
        ${tw`px-2 py-1`}
    }

    * {
        ${tw`text-sm`}
    }
    tr:nth-child(even) {background: #eaeaea}
`;

import { EnrollmentSummary, StudentEnrollment } from '@/api/types';

export const summarizeStats = (enrollments: StudentEnrollment[]): EnrollmentSummary => {
    let totalUnits = 0;
    let totalGradedUnits = 0;
    let weightedGradePoints = 0;
    enrollments.forEach((o) => {

        if (o.earnCredit === 'Y') {
            totalUnits += o.unitsEarned;

            if (o.gradePoints) {
                const weightedPoints = (o.gradePoints * o.unitsEarned);
                totalGradedUnits += o.unitsEarned;
                weightedGradePoints += weightedPoints;
            }
        }
    });

    const cumulativeGpa = (weightedGradePoints / totalGradedUnits);
    const summary: EnrollmentSummary = {
        totalUnits,
        cumulativeGpa
    };
    return summary;
};

import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';

interface Props {
    completed: boolean;
    message: string;
}

interface TextStyleProps {
    completed: boolean;
}

const TextStyle = styled.p<TextStyleProps>`
    ${tw`text-xs`}
    color: ${attrs => attrs.completed ? tw`text-green-500` : tw`text-red-500`}
`;

export default ({ completed, message }: Props) => {
    return <TextStyle completed={completed}>{message}</TextStyle>;
};

import {RuleGroupRequirement, SubGroup, SubGroupRuleEntry} from '@/degrees/rules';
import {StudentInfo} from '@/api/types';
import _ from 'lodash';
import {AuditedRuleEntry, AuditStatus, RuleEntryAuditor} from '@/degrees/audit/types';

export default async function auditGroupRule(rule: RuleGroupRequirement, studentInfo: StudentInfo, auditRuleEntry: RuleEntryAuditor): Promise<AuditedRuleEntry> {

    const subgroupEntries: SubGroup[] = rule.group_list;

    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        // enrollments: studentInfo.enrollments,
    };

    const auditedSubgroups: SubGroup[] = [];

    for (let i = 0; i < subgroupEntries.length; i++) {
        const auditSubgroupEntries: SubGroup = [];

        for (let j = 0; j < subgroupEntries[i].length; j++) {
            const audited = await auditRuleEntry(subgroupEntries[i][j], studentInfo) as AuditedRuleEntry<SubGroupRuleEntry>;
            auditSubgroupEntries.push(audited);
        }

        auditedSubgroups.push(auditSubgroupEntries);

    }

    const completedSubgroups = auditedSubgroups.filter((subgroup: SubGroup) => {
        let completedSubgroup = true;
        _.forEach(subgroup, (auditedEntry: AuditedRuleEntry<SubGroupRuleEntry>) => {
            if (!auditedEntry.completed) {
                completedSubgroup = false;
                return false;
            }
        });
        return completedSubgroup;
    });

    if (completedSubgroups.length !== _.toNumber(rule.number)) {
        auditStatus.completed = false;
        auditStatus.message = 'One or more groups not met.';
    }

    return {
        group_requirement: {
            ...rule,
            group_list: auditedSubgroups,
        },
        ...auditStatus
    };
}

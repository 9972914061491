import { gql } from '@apollo/client';

// requirementText: requirement_text

export default gql`
fragment requirementBlock on requirement_blocks {
    requirementId: requirement_id	
    institution
    blockType: block_type
    blockValue: block_value
    title
    
    parseTree: parse_tree
    activeValid: active_valid
    customParser: custom_parser
}
`;

import React, { useEffect, useState } from 'react';
import {BrowserRouter, Redirect, Route, RouteComponentProps, Switch} from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import GlobalStyle from '@css/GlobalStyle';
import HomeScreen from '@/components/HomeScreen';
import {withAdminAuth, withAuth} from '@/api/AuthService';
import store, { useStoreActions, useStoreState } from '@/store';
import { StoreProvider, useStoreRehydrated } from 'easy-peasy';
import config from '@/config';
import EntryScreen from '@/components/login/EntryScreen';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router';
import FrameworkUpdateWatcher from '@/components/FrameworkUpdateWatcher';
import { Spinner } from '@/components/common';
import getAuthenticatedUser from '@/api/getAuthenticatedUser';
import BroadcastManager from '@/components/BroadcastManager';
import 'chart.js/auto';
import LoginScreen from '@/components/login/LoginScreen';
import 'react-datepicker/dist/react-datepicker.css';
import DegreeContainerV2 from '@/components/degree/DegreeContainerV2';
import requireAdmin from '@/components/dashboard/requireAdmin';
import Header from '@/components/Header';

console.log('version: ', config.version);

const renderAuthContainer = (Component: React.ComponentType) => {
    return (_props: RouteComponentProps) => {
        return (
            <div>
                <Header/>
                <Component/>
            </div>
        );
    };

};

const AppLoader = () => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const location = useLocation();

    const token = useStoreState(state => state.user.token);

    const login = useStoreActions(actions => actions.login);
    const tokenLogin = useStoreActions(actions => actions.tokenLogin);
    const logout = useStoreActions(actions => actions.logout);

    useEffect(() => {
        BroadcastManager.init();
        BroadcastManager.onMessage((ev) => {
            console.log('heard message ev: ', ev);
            const { token } = store.getState().user;

            if (token) {
                if (ev.data === 'logout') {
                    logout({emit: false});
                } else {
                    try {
                        const data = JSON.parse(ev.data);
                        const { studentInfo } = store.getState().student;

                        if (data.type === 'login' && data.studentId !== studentInfo?.studentId) {
                            logout({emit: false});
                        }

                    } catch (err) {

                    }
                }
            }

        });
        return () => {
            BroadcastManager.cleanup();
        };

    }, []);

    useEffect(() => {
        if (token) {
            getAuthenticatedUser();
        }
    }, [ token ]);

    if (loading) return null;
    return (
        <>
            <FrameworkUpdateWatcher/>

            <Switch>
                <Route path="/login" component={LoginScreen}/>
                <Route path='/' component={withAdminAuth(DegreeContainerV2)}/>
                <Redirect to={'/'}/>
            </Switch>
        </>

    );
};

const HydratedApp = () => {
    const isHydrated = useStoreRehydrated();

    return (
        isHydrated ?
            <BrowserRouter>
                <AppLoader/>
            </BrowserRouter>
            :
            <Spinner/>
    );
};

const App = () => (
    <Sentry.ErrorBoundary>
        <GlobalStyle/>
        <StoreProvider store={store}>
            <HydratedApp/>
        </StoreProvider>

    </Sentry.ErrorBoundary>
);

export default hot(App);

import React, { useState } from 'react';
import {
    formatGPA,
} from '@/api/transformers';
import tw from 'twin.macro';
import { Spinner } from '../common';
import EnrollmentHistoryTable from '@/components/enrollment-history/EnrollmentHistoryTable';
import RuleStatus from '@/components/degree/RuleStatus';
import {
    BaseButtonStyle,
} from '@/components/dashboard/styled';
import {AuditedRequirementBlock, AuditedRuleEntry, StudentInfoWithDegree} from '@/degrees/audit/types';
import {
    formatBlockExtract,
    formatCourseList, formatMajorDescription, formatNumberRange,
    formatRuleEntryLabel,
    getRuleType,
} from '@/degrees/audit/utils';
import {
    RuleConditional,
    RuleGroupRequirement,
    RuleSubset,
    SubGroup,
    SubsetRuleEntry
} from '@/degrees/rules';
import {
    AuditedBlockStyle,
    AuditedRuleEntryStyle,
    DegreeRequirementsStyle, EnrollmentHistoryContainerStyle, PageContainerStyle,
    StudentFormStyle
} from '@/components/degree/styled';
import {InstitutionName} from '@/constants/institutions';
import {auditStudent} from '@/components/degree/auditStudent';
import VersionInfo from '@/components/VersionInfo';
import _ from 'lodash';

// student w/ 2nd degree
// const defaultStudentId = '923317041';

// const defaultStudentId = '14121086';

// transfer student
// const defaultStudentId = '23977875';

const defaultStudentId = '';

const dummyInfo = {
    studentId: '923317041',
    degree: 'BS',
    institution: 'BKL01',
    major: 'COMPSC',
    conc: 'N/A',
    cumulativeGpa: 2.72,
    totalUnits: 106,
    latestDeclaredPlan: {
        "__typename": "ps_acad_plan",
        "effdt": "2021-03-04",
        "academicPlan": "COMPSC-BS",
        "declaredDate": "2020-12-05",
        "reqTerm": "1219",
        "completionTerm": "",
        "plan": {
            "__typename": "ps_acad_plan_tbl",
            "transcriptDescription": "Computer and Information Science",
            "institution": "BKL01"
        }
    }
} as any;

export default () => {

    const [ error, setError ] = useState<string>('');
    const [ studentId, setStudentId ] = useState<string>(defaultStudentId);
    const [ studentInfo, setStudentInfo ] = useState<StudentInfoWithDegree | null>();

    const [ loading, setLoading ] = useState<boolean>(false);

    const [ auditedBlock, setAuditedBlock ] = useState<AuditedRequirementBlock | null>(null);
    const [ auditErrors, setAuditErrors ] = useState<string[]>([]);

    const onClearStudent = () => {
        setStudentInfo(null);
        setAuditedBlock(null);
    };

    const onSubmitStudentId = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        setStudentInfo(null);
        setStudentId('');
        setError('');

        auditStudent(studentId)
            .then((results) => {
                setStudentInfo(results.studentInfo);
                setAuditedBlock(results.auditedBlock);
                setAuditErrors(_.cloneDeep(results.errors));
            })
            .catch(err => {
                console.error(err);
                setError(err.toString());
            })
            .then(() => setLoading(false));
    };

    function renderSubsetRule(entry: AuditedRuleEntry<{subset: RuleSubset}>) {
        return (
            <div>
                <p>Requirements:</p>
                <div css={[ tw`mx-4` ]}>
                    {entry.subset.requirements.map((req: AuditedRuleEntry<SubsetRuleEntry>, index) => {
                        return (
                            <div key={index}>
                                {renderAuditedRuleEntry(req)}
                            </div>

                        );
                    })}
                </div>
            </div>
        );
    }

    function renderConditionalRule(entry: AuditedRuleEntry<{conditional: RuleConditional}>) {
        const rule = entry.conditional;

        const branch = entry.satisfiedConditionStr ? rule.if_true : (rule.if_false || []);

        return (
          <div>
              <p>IF {rule.condition_str}: {(entry.satisfiedConditionStr!).toString()}</p>

              {
                  !entry.satisfiedConditionStr && rule.if_false?.length ?
                      <p>ELSE</p>
                      : null
              }

              <div css={[ tw`mx-4` ]}>
                  {branch.map((entry, index) => {
                      return (
                          <div key={index}>
                              {renderAuditedRuleEntry(entry)}
                          </div>
                      );
                  })}
              </div>
              {/* { */}
              {/*     rule.if_false && rule.if_false.length ? */}
              {/*         <> */}
              {/*             <p>ELSE</p> */}
              {/*             <div css={[ tw`mx-4` ]}> */}
              {/*                 {rule.if_false.map((entry, index) => { */}
              {/*                     return ( */}
              {/*                         <div key={index}> */}
              {/*                             {renderAuditedRuleEntry(entry)} */}
              {/*                         </div> */}
              {/*                     ); */}
              {/*                 })} */}
              {/*             </div> */}
              {/*         </> */}
              {/*         : null */}
              {/* } */}
          </div>
        );
    }

    function renderGroupRule(entry: AuditedRuleEntry<{group_requirement: RuleGroupRequirement}>) {
        const rule = entry.group_requirement;
        return (
            <div>
                <p>{rule.number} Groups in</p>


                {rule.group_list.map((subgroup: SubGroup, index: number) => {
                    return (
                        <div key={index}>
                            <p>Group #{index + 1}</p>
                            <div css={[ tw`mx-8` ]}>
                                {subgroup.map((entry, index) => {
                                    return (
                                        <div key={index}>
                                            {renderAuditedRuleEntry(entry)}
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                    );

                })}
            </div>
        );
    }

    function renderEntryDetails(entry: AuditedRuleEntry) {
        if (entry.ignored) return null;

        if ('class_credit' in entry) {
            return renderClassCreditRule(entry);
        }
        if ('subset' in entry) {
            return renderSubsetRule(entry);
        }
        if ('conditional' in entry) {
            return renderConditionalRule(entry);
        }
        if ('group_requirement' in entry) {
            return renderGroupRule(entry);
        }
        if ('remark' in entry) {
            return <p>{entry.remark}</p>;
        }
        if ('remark_str' in entry) {
            return <p>{entry.remark_str}</p>;
        }
        if ('block' in entry) {
            if (!entry.block.populatedBlock) {
                console.error('block entry has no populated block. entry: ', entry);
                throw new Error('no populated block');
            }
            return renderAuditedBlock(entry.block.populatedBlock!);
        }
        if ('copy_rules' in entry) {
            throw new Error('unhandled copy_rules');
        }
        if ('blocktype' in entry) {
            return renderAuditedBlock(entry.blocktype.populatedBlock!);
        }
        if ('noncourse' in entry) {
            return (
                <p>{entry.noncourse.expression}</p>
            );
        }
        if ('proxy_advice' in entry) {
            return (
                <p>{entry.proxy_advice.advice_str}</p>
            );
        }
        if ('course_list_rule' in entry) {
            return (
                <div>
                    <p>minarea: {entry.course_list_rule.minarea}</p>
                    <p>Courses: {formatCourseList(entry.course_list_rule.course_list)}</p>
                </div>
            );
        }
        if ('rule_complete' in entry) {
            return (
                <p>Rule Complete</p>
            );
        }
    }

    function renderClassCreditRule(entry: AuditedRuleEntry) {
        const rule = (entry as any).class_credit;
        const messages: string[] = [];
        if (rule.mingpa) {
            messages.push(`Minimum GPA: ${rule.mingpa}`);
        }
        if (rule.mingrade) {
            messages.push(`Minimum Grade: ${rule.mingrade}`);
        }
        if (rule.minclass) {
            const s = `Min Class: ${rule.minclass.number}\n\tCourse List:\t\n${formatCourseList(rule.minclass.course_list)}`;
            messages.push(s);
        }
        if (rule.mincredit) {
            const s = `Min Credit: ${rule.mincredit.number}\n\tCourse List:\t\n${formatCourseList(rule.mincredit.course_list)}`;
            messages.push(s);
        }
        if (rule.min_credits && rule.max_credits) {
            messages.push(`CREDITS: ${formatNumberRange(rule.min_credits, rule.max_credits)}`);
        }
        if (rule.min_classes && rule.max_classes) {
            messages.push(`CLASSES: ${formatNumberRange(rule.min_classes, rule.max_classes)}`);
        }
        if (rule.course_list) {
            messages.push(`\t IN\t${formatCourseList(rule.course_list)}`);
        }
        const s = messages.join('\n');
        return <p>{s}</p>;
    }

    function renderAuditedRuleEntry(entry: AuditedRuleEntry, isRoot: boolean = false) {


        return (
            <AuditedRuleEntryStyle isRoot={isRoot}>
                <div css={[ tw`flex flex-row` ]}>

                </div>

                <div>
                    <span css={[ tw`font-normal text-sm uppercase text-gray-500` ]}>Rule - {getRuleType(entry)}</span>
                    <p>{formatRuleEntryLabel(entry)}</p>
                </div>
                <RuleStatus entry={entry}/>
                {/* <p css={[ tw`underline font-medium` ]}>{formatRuleEntryLabel(entry)}</p> */}

                <div css={[ tw`mt-3` ]}>
                    <div css={[ tw`ml-4` ]}>
                        {
                            renderEntryDetails(entry)
                        }
                    </div>
                </div>
            </AuditedRuleEntryStyle>
        );
    }

    function renderAuditedBlock(block: AuditedRequirementBlock) {
        return (
            <AuditedBlockStyle>
                <div>
                    {/* <span css={[tw`italic`]}>Block - {formatBlockExtract(block)}</span> */}
                    <p css={[ tw`font-medium text-xl` ]}>{block.title}</p>
                </div>
                {/* <p css={[ tw`underline font-medium` ]}>{block.title}</p> */}

                <div css={[ ]}>
                    <p css={[ tw`font-light text-sm text-gray-500` ]}>BLOCK - {formatBlockExtract(block)}</p>
                    <RuleStatus entry={block}/>
                    <div css={[ tw`mx-8` ]}>
                        {
                            block.parseTree.body_list.map((entry, i) => (
                                <div key={i}>
                                    {renderAuditedRuleEntry(entry, true)}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </AuditedBlockStyle>
        );
    }

    return (
        <PageContainerStyle css={[ tw`max-w-screen-xl` ]}>

            <VersionInfo/>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <StudentFormStyle onSubmit={onSubmitStudentId}>
                {/* <div className={'header'}> */}
                {/*     <h3>Search</h3> */}
                {/* </div> */}

                <div css={[ tw`mt-4 mb-8` ]}>
                    {/* <label css={[ tw`mb-2 block font-medium text-xl` ]}>Search Student</label> */}
                    <div css={[ tw`flex` ]}>
                        <input placeholder={'Enter Student ID'} type={'text'} value={studentId} onChange={e => setStudentId(e.target.value)} required/>
                        <BaseButtonStyle css={[ tw`mx-4 bg-indigo-700 text-white` ]} onClick={onSubmitStudentId} disabled={!studentId}>Submit</BaseButtonStyle>
                    </div>
                </div>

                {
                    error ?
                        <div>
                            <p css={[ tw`text-red-400` ]}>{error}</p>
                        </div>
                        : null
                }


                {
                    studentInfo ?
                        <div>
                            <div className={'header'} css={[ tw`flex justify-between items-center` ]}>
                                <h3>Student Details</h3>
                                <span onClick={() => onClearStudent()} css={[ tw`cursor-pointer hover:underline text-indigo-500` ]}>Clear Student</span>
                            </div>

                            <div>
                                {
                                    auditErrors.length ?
                                        auditErrors.map(error => (
                                            <p key={error} className={'error'} css={[ tw`font-medium text-red-500 mb-4` ]}>{error}</p>
                                        ))
                                        : null
                                }
                            </div>

                            <div className={'details-container'}>

                                <div>
                                    <div className={'field'}>
                                        <label>Student ID:</label>
                                        <span>{studentInfo.studentId}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Degree:</label>
                                        <span>{studentInfo.degree ? `${studentInfo.degree} (${InstitutionName[studentInfo.institution] || studentInfo.institution})` : 'N/A'}</span>
                                    </div>
                                    <div className={'field'}>
                                        <label>Major:</label>
                                        <span>{formatMajorDescription(studentInfo.latestDeclaredPlan) || 'N/A'}</span>
                                    </div>
                                </div>

                                <div>
                                    <div className={'field'}>
                                        <label>Concentration:</label>
                                        <span>{studentInfo.conc || 'N/A'}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Cumulative GPA: </label>
                                        <span>{formatGPA(studentInfo.cumulativeGpa)}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Total Units Earned: </label>
                                        <span>{studentInfo.totalUnits}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : null

                }


            </StudentFormStyle>

            {/* <GalleryGridStyle> */}
            {/*     <GalleryGridItemStyle */}
            {/*         rowSpan={1} */}
            {/*         colSpan={1} */}
            {/*     > */}
            {/*         <DegreeBlocksSearchForm degreeInfo={studentInfo} onSubmit={onDegreeBlocksSearchSubmit}/> */}


            {/*     </GalleryGridItemStyle> */}
            {/* </GalleryGridStyle> */}

            {
                auditedBlock ?
                    <DegreeRequirementsStyle>
                        <div className={'header'}>
                            <h3>Degree Requirements</h3>
                        </div>
                        {/* { */}
                        {/*     studentInfo ? */}
                        {/*         <div css={[ tw`mb-2` ]}> */}
                        {/*             <p css={[ tw`mb-1` ]}>Student: {studentInfo.studentId}</p> */}
                        {/*             <button type={'button'} onClick={onClearStudent}>Clear Student</button> */}
                        {/*         </div> */}
                        {/*         : null */}
                        {/* } */}
                        {
                            auditedBlock ?
                                <div css={[ tw`` ]}>
                                    {renderAuditedBlock(auditedBlock)}
                                </div>
                                : null
                        }
                    </DegreeRequirementsStyle>
                    : null
            }

            {
                studentInfo && !loading ?
                    <EnrollmentHistoryContainerStyle>
                        <div className={'header'}>
                            <h3>Enrollment History</h3>
                        </div>
                        <EnrollmentHistoryTable enrollments={studentInfo.enrollments}/>
                    </EnrollmentHistoryContainerStyle>
                    :
                    null
            }



        </PageContainerStyle>
    );
};

import {RuleRemark} from '@/degrees/rules';
import {StudentInfo} from '@/api/types';
import {AuditedRuleEntry, AuditStatus} from '@/degrees/audit/types';

// auditRemarkRule literally just a string
export default function auditRemarkRule(rule: RuleRemark, { enrollments }: StudentInfo): AuditedRuleEntry {

    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
    };

    return {
        remark: rule,
        ...auditStatus
    };
}

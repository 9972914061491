import logipar from 'logipar/Logipar';

export type LogiparTokenType = 'AND' | 'OR' | 'LITERAL';
export type LogiparToken = {
    type: LogiparTokenType,
    literal: string | undefined,
}
export type LogiparNode = {
    token: LogiparToken,
    left?: LogiparNode,
    right?: LogiparNode,
    parent?: LogiparNode,
}

export function evaluateNode(node: LogiparNode, data: any, evaluator: (token: string, data: any) => boolean | string): boolean | string {
    if (node.token.type === 'LITERAL') {
        // Evaluate the condition using the provided data
        return evaluator(node.token.literal!, data);
    } else {
        // This is an AND or OR operator, so recursively evaluate the left and right child nodes
        const leftResult = node.left ? evaluateNode(node.left, data, evaluator) : true;
        const rightResult = node.right ? evaluateNode(node.right, data, evaluator) : true;

        // Combine the results based on the operator
        if (node.token.type === 'AND') {
            return leftResult && rightResult;
        } else { // OR
            return leftResult || rightResult;
        }
    }
}

export function parseConditionExpression(str: string): LogiparNode {
    const lp = new logipar.Logipar();
    lp.caseSensitive = false;
    try {
        const tree = lp.parse(str);
        return tree;
    } catch (err) {
        console.error('parseConditionExpression err: ', err, ' provided str: ', str);
        throw err;
    }
}

import config from '@/config';
import { ApolloProvider } from '@apollo/client';
import graphClient from '@/api/graph';
import http from '@/api/http';
import { AccountRole, LoginResponse, StudentInfo } from '@/api/types';
import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router';
import {Link, RouteComponentProps, useHistory} from 'react-router-dom';
import {useStoreActions, useStoreState} from '@/store';
import { isCohortAllowed } from '@/store/simulation/simulationUtils';
import {CUNYFirstLogoStyle, HeaderStyle} from '@/components/Header';
import CUNYFirstLogo from '@images/cuny-first-logo.jpg';
import tw from 'twin.macro';
const MyContext = React.createContext<StudentInfo | null>(null);
const RoleContext = React.createContext<AccountRole | undefined | null>(null);

export const useMe = () => {
    return useContext(MyContext);
};

export const useRole = () => {
    return useContext(RoleContext);
};

export const withAdminAuth = (Component: React.ComponentType) => {
    return (_props: RouteComponentProps) => {
        const history = useHistory();

        const token = useStoreState(state => state.user.token);
        const role = useStoreState(state => state.user.role);
        const student = useStoreState(state => state.student);

        const logout = useStoreActions(actions => actions.logout);

        const onLogout = () => {
            logout();
        };

        useEffect(() => {
            if (!token) {
                history.replace('/login');

            }
        }, [ token ]);

        if (!token) return <Redirect to="/login" />;

        if (role !== 'dev') {
            return <Redirect to='/login'/>;
        }

        return (
            <ApolloProvider client={graphClient}>
                <RoleContext.Provider value={role}>
                    <MyContext.Provider value={student.studentInfo}>
                        <div>
                            <HeaderStyle>
                                <Link className="logo-link" to="/">
                                    <CUNYFirstLogoStyle src={CUNYFirstLogo}/>
                                </Link>

                                <div className="nav-links">
                                    <a key="logout" onClick={() => onLogout()} css={[tw`text-white hover:opacity-75 cursor-pointer`]}>Logout</a>
                                </div>
                            </HeaderStyle>
                            <Component/>
                        </div>


                    </MyContext.Provider>
                </RoleContext.Provider>
            </ApolloProvider>
        );
    };
};


export const withAuth = (Component: React.ComponentType) => {
    return (_props: RouteComponentProps) => {
        const history = useHistory();

        const token = useStoreState(state => state.user.token);
        const role = useStoreState(state => state.user.role);
        const student = useStoreState(state => state.student);

        useEffect(() => {
            if (!token) {
                history.replace('/login');

            } else if (student.studentInfo && !isCohortAllowed(student.studentInfo.cohort)) {
                history.replace('/screener');
            }
        }, [ token, student.studentInfo?.cohort ]);

        if (!token) return <Redirect to="/login" />;

        return (
            <ApolloProvider client={graphClient}>
                <RoleContext.Provider value={role}>
                    <MyContext.Provider value={student.studentInfo}>
                        <Component/>
                    </MyContext.Provider>
                </RoleContext.Provider>
            </ApolloProvider>
        );
    };
};


export const signUp = async (username: string, password: string): Promise<LoginResponse> => {
    const endpoint = `${config.apiUrl}/signup`;
    const { data } = await http.post(endpoint, { username, password });
    return data;
};

// export const studentLogin = async (studentId: string): Promise<LoginResponse | any> => {
//     const endpoint = `${config.apiUrl}/student-login`;
//     const { data } = await http.post(endpoint, { studentId });
//     console.log('studentLogin data: ', data);
//     const token = data.token;
//     storeStudentId(studentId);
//     storeAuthHeader(token);
//
//     await initializeStudentEntry(studentId, data.nameDisplay);
//     return data;
// };

// export const clearState = () => {
//     const actions = store.getActions();
//     actions.logout();
//
//     Sentry.setUser(null);
//
//     localStorage.removeItem('Authorization');
//     localStorage.removeItem('studentId');
//     // noinspection JSIgnoredPromiseFromCall
//     graphClient.clearStore();
//
// };

// export const logout = () => {
//     clearState();
//     // setTimeout(() => {
//     //     window.location.href = '/?' + Date.now();
//     // }, 50);
//
// };

export default {
    useMe,
    withAuth,
    signUp,
};

import React, {useEffect, useState, useRef} from 'react';
import tw from 'twin.macro';
import { Spinner } from '@/components/common';
import styled from 'styled-components/macro';
import { useStoreActions } from '@/store';
import { useHistory } from 'react-router-dom';
import {BaseButtonStyle} from '@/components/dashboard/styled';

const ContainerStyle = styled.div`
    ${tw`max-w-screen-md h-screen flex`}
    margin: auto;
    ${tw`pt-4 pb-4 px-4 md:px-12`}
    form {
        margin: auto;
        ${tw`w-full max-w-sm`}
    }
    input {
        -webkit-appearance: none;
        border: 1px solid grey;
        border-radius: 3px;
        padding-left: 8px;
        padding-right: 8px;
        ${tw`text-lg w-full`}
    }
    
    button.sign-in {
        ${tw`hover:opacity-100 opacity-100`}
    }
`;

const LoginScreen = () => {
    const history = useHistory();

    const usernameInputRef = useRef<HTMLInputElement>(null);

    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');

    const [ error, setError ] = useState<string>('');
    const [ submitting, setSubmitting ] = useState<boolean>(false);

    const accountLogin = useStoreActions(actions => actions.accountLogin);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('submitting...' + username);
        if (username === 'error') {
            throw new Error('testing error capturing');
        }

        if (submitting || !username) return;

        setError('');
        setSubmitting(true);

        accountLogin({ username, password }).then(errMsg => {
            console.log('errMsg: ', errMsg);
            if (!errMsg) {
                history.replace('/');
            } else {
                setSubmitting(false);
                setError(errMsg);
            }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            usernameInputRef.current?.focus();
        }, 0);
    }, []);

    return (
        <ContainerStyle>
            <form onSubmit={(e) => onSubmit(e)}>

                <div css={[ tw`flex flex-col items-center` ]}>
                    <div css={[ tw`mb-3 w-full` ]}>
                        <p css={[ tw`font-normal pr-2 text-lg` ]}>Username</p>
                        <input ref={usernameInputRef} css={[ tw`align-middle py-2 px-3` ]} value={username} onChange={(e: any) => setUsername(e.target.value)} required/>
                    </div>
                    <div css={[ tw`mb-3 w-full` ]}>
                        <p css={[ tw`font-normal pr-2 text-lg` ]}>Password</p>
                        <input css={[ tw`align-middle py-2 px-3` ]} type="password" value={password} onChange={(e: any) => setPassword(e.target.value)} required/>
                    </div>
                </div>

                <div css={[ tw`mt-4 flex justify-center` ]}>
                    {
                        submitting ?

                            <Spinner size={'base'} />
                            : <BaseButtonStyle className={'sign-in'} css={[ tw`w-full py-2 bg-gray-900 text-white text-lg` ]} type={'submit'} disabled={!username}>Sign In</BaseButtonStyle>
                        // <Button disabled={!cunyId} css={[tw`text-lg`} type="submit">Login</Button>
                    }
                </div>

                {
                    error ?
                        <div css={[ tw`w-full mt-4 text-red-600 rounded mb-2 text-center` ]}>
                            {error}
                        </div>
                        : null
                }

            </form>


        </ContainerStyle>
    );
};

export default LoginScreen;

import search, { SearchStore } from '@/store/search';
import student, { StudentStore } from '@/store/student';
import { createStore, createTypedHooks, persist, Store, Thunk, thunk } from 'easy-peasy';
import { composeWithDevTools } from 'redux-devtools-extension';
import config from '@/config';
import user, { UserStore } from '@/store/user';
import { generateEmptyClassSearchForm } from '@/api/transformers';
import * as Sentry from '@sentry/react';
import graphClient from '@/api/graph';
import {AccountLoginParams, LoginParams, LoginResponse, LogoutParams} from '@/api/types';
import studentLogin from '@/api/studentLogin';
import { initializeStudentEntry } from '@/api/simulation/SimulationAPI';
import getAuthenticatedUser from '@/api/getAuthenticatedUser';
import BroadcastManager from '@/components/BroadcastManager';
import _ from 'lodash';
import adminLogin from '@/api/adminLogin';
import accountLogin from '@/api/accountLogin';
import getStudentInfoBasic from '@/api/graphql/queries/getStudentInfoBasic';

let _store: Store<StoreModel>;

export interface StoreModel {
    search: SearchStore;
    student: StudentStore;

    user: UserStore;
    hashCheck: string;


    tokenLogin: Thunk<StoreModel, undefined, any, StoreModel, Promise<void | string>>;
    login: Thunk<StoreModel, LoginParams, any, StoreModel, Promise<void | string>>;
    accountLogin: Thunk<StoreModel, AccountLoginParams, any, StoreModel, Promise<void | string>>;
    adminLogin: Thunk<StoreModel, LoginParams, any, StoreModel, Promise<void | string>>;
    logout: Thunk<StoreModel, LogoutParams | undefined, any, StoreModel, Promise<void>>;

    onLoginResponse: Thunk<StoreModel, string, any, StoreModel, Promise<void | string>>;
}

const store: StoreModel = {
    search,
    student,
    hashCheck: config.version,

    tokenLogin: thunk(async (actions, payload) => {
        try {
            const data = await getAuthenticatedUser();
            if (data.role === 'dev') {
                return actions.onLoginResponse(data.studentId);
            } else {
                return actions.login(data);
            }
        } catch (err) {
            await actions.logout();
        }
    }),

    accountLogin: thunk(async (actions, payload) => {
        try {
            const loginResponse = await accountLogin(payload.username, payload.password);
            console.log('loginResponse: ', loginResponse);
            actions.user.setToken(loginResponse.token);
            actions.user.setRole(loginResponse.role);


        } catch (err) {
            await actions.logout();
            const errMsg = _.get(err, 'response.data.msg', 'Unable to log in. Please contact support.');
            return errMsg;
        }
    }),

    adminLogin: thunk(async (actions, payload) => {
        try {
            const loginResponse = await adminLogin(payload.studentId, payload.password);
            actions.user.setToken(loginResponse.token);
            actions.user.setRole(loginResponse.role);

            await actions.onLoginResponse(loginResponse.studentId);

        } catch (err) {
            await actions.logout();
            const errMsg = _.get(err, 'response.data.msg', 'Unable to log in. Please contact support at elearningresearch@bmcc.cuny.edu.');
            return errMsg;
        }
    }),

    login: thunk(async (actions, payload) => {
        try {
            console.log('payload loginParams: ', payload);

            const loginResponse = await studentLogin(payload.studentId);

            actions.user.setToken(loginResponse.token);
            actions.user.setRole(loginResponse.role);

            await actions.onLoginResponse(loginResponse.studentId);

        } catch (err) {
            await actions.logout();
            const errMsg = _.get(err, 'response.data.msg', 'Unable to log in. Please contact support at elearningresearch@bmcc.cuny.edu.');
            return errMsg;
        }
    }),

    onLoginResponse: thunk(async (actions, studentId) => {
        await initializeStudentEntry(studentId);

        const results = await graphClient.query({
            query: getStudentInfoBasic,
            variables: { studentId },
            fetchPolicy: 'network-only',
        });
        actions.student.setStudentInfo(results.data.items[0]);
        actions.search.setFilters(generateEmptyClassSearchForm());
        actions.search.setSelectedCourseClass(null);
        actions.search.setSubjects([]);

        BroadcastManager.send(JSON.stringify({
            type: 'login',
            studentId,
        }));
    }),

    logout: thunk(async (actions, payload = {emit: true}, helpers) => {
        console.log('dispatching logout thunk');
        if (payload?.emit) {
            BroadcastManager.send('logout');
        }

        actions.user.setToken(null);
        actions.user.setRole(null);

        actions.search.setFilters(generateEmptyClassSearchForm());
        actions.search.setSelectedCourseClass(null);
        actions.search.setSubjects([]);
        actions.search.setAcknowledgedCourseRequisitesMap({});

        actions.student.setStudentInfo(null);

        Sentry.setUser(null);
        await graphClient.clearStore();

        await getStore().persist.flush();
    }),
    user: persist(user, {
        storage: 'localStorage',
        mergeStrategy: 'mergeDeep',
    })
};

const hooks = createTypedHooks<StoreModel>();
export const useStore = hooks.useStore;
export const useStoreState = hooks.useStoreState;
export const useStoreActions = hooks.useStoreActions;
export const useStoreDispatch = hooks.useStoreDispatch;

export const getStore = () => _store;

export default (() => {
    _store = createStore(store, {
        compose: composeWithDevTools({name: 'GlobalStore'})
    }
    );
    return _store;
})();

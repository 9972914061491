import {ConditionalRuleEntry, RuleConditional, ScribedCourse} from '@/degrees/rules';
import {AcademicPlanInfo, StudentInfo} from '@/api/types';
import _ from 'lodash';
import {
    AuditedRuleEntry,
    AuditStatus,
    EvaluatedExpression,
    RuleEntryAuditor,
    StudentInfoWithDegree
} from '@/degrees/audit/types';
import {evaluateNode, parseConditionExpression} from '@/degrees/audit/logicparUtils';
import {ConditionOperator, ScribeCode} from '@/degrees/audit/constants';
import {
    formatCompletedDegree,
    formatDegreeMajor,
    getPriorDegrees,
    getQualifyingEnrollments,
} from '@/degrees/audit/utils';
import {parseClassFromStudentEnrollmentHistory} from '@/api/transformers';

const withAttributeRegex = /\(\s*WITH[^()]*\)/ig;

function stripIgnoredLogic(input: string) {
    let cleaned = input.replace(withAttributeRegex, '');
    return cleaned;
}

export default async function auditConditionalRule(rule: RuleConditional, studentInfo: StudentInfo, auditRuleEntry: RuleEntryAuditor): Promise<AuditedRuleEntry> {

    const { condition_str, if_true, if_false } = rule;

    const cleaned = stripIgnoredLogic(condition_str);

    const tree = parseConditionExpression(cleaned);
    let isTrueBranch: boolean | string = false;

    try {
        isTrueBranch = evaluateNode(tree, studentInfo, evaluateConditions);
    } catch (err) {
        console.error('error in auditConditionalRule. rule: ', rule);
        throw new Error(err);
    }



    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        satisfiedConditionStr: isTrueBranch
    };

    const auditedRule = _.cloneDeep(rule);


    // Note: Auditing both true/false branches is only required for display purposes (i.e. ensuring block rules have been populated prior to rendering).
    // Otherwise technically we only need to audit the specific branch determined by the condition_str

    const auditedIfTrue: AuditedRuleEntry<ConditionalRuleEntry>[] = [];
    for (const rule of if_true) {
        const audited = await auditRuleEntry(rule, studentInfo) as AuditedRuleEntry<ConditionalRuleEntry>;
        auditedIfTrue.push(audited);
    }
    auditedRule.if_true = auditedIfTrue;

    if (if_false) {
        const auditedIfFalse: AuditedRuleEntry<ConditionalRuleEntry>[] = [];
        for (const rule of if_false) {
            const audited = await auditRuleEntry(rule, studentInfo) as AuditedRuleEntry<ConditionalRuleEntry>;
            auditedIfFalse.push(audited);
        }

        auditedRule.if_false = auditedIfFalse;
    }

    // completed means no failed rules in the chosen branch
    auditStatus.completed = !(isTrueBranch
        ? auditedRule.if_true.find((o: AuditedRuleEntry) => !o.completed)
            // automatically complete rule if condition wasn't met _and_ there is no `else` branch, since that would result in no actual rules being applied
        : (auditedRule.if_false || []).find((o: AuditedRuleEntry) => !o.completed)
    );
    if (!auditStatus.completed) {
        auditStatus.message = 'One or more rules not met.';
    }

    const result = {
        conditional: auditedRule,
        ...auditStatus
    };
    return result;
}

export function evaluateExpression(condition: EvaluatedExpression, studentInfo: StudentInfoWithDegree) {
    const { key, value } = condition;

    const normalizedKey = key.toUpperCase();
    const normalizedValue = parseConditionValue(value);

    if (normalizedKey === ScribeCode.PriorDegree) {

        const priorDegrees = getPriorDegrees(studentInfo).map(plan => {
            return formatCompletedDegree(plan);
        });
        if (!normalizedValue && priorDegrees.length) return false;
        return `true: ${priorDegrees}`;

    } else if (normalizedKey === ScribeCode.PriorDegreeBA) {
        const priorDegrees = getPriorDegrees(studentInfo);

        const match: AcademicPlanInfo | undefined = _.find<AcademicPlanInfo>(priorDegrees, (o) => !!(o.academicPlan.match(/-BA\s*$/i)));

        if (normalizedValue) {
            const result = match ? `true: ${formatCompletedDegree(match)}` : false;
            return result;
            // if normalizedValue is false then that implies exclude condition
        } else {
            return !match;
        }

    } else if (normalizedKey === ScribeCode.PriorDegreeBS) {
        const priorDegrees = getPriorDegrees(studentInfo);

        const match: AcademicPlanInfo | undefined = _.find<AcademicPlanInfo>(priorDegrees, (o) => !!(o.academicPlan.match(/-BS\s*$/i)));

        if (normalizedValue) {
            const result = match ? `true: ${formatCompletedDegree(match)}` : false;
            // if normalizedValue is false then that implies exclude condition
        } else {
            return !match;
        }

    } else if (normalizedKey === ScribeCode.AUDITACTION) {
        return false;
    } else if (normalizedKey === ScribeCode.Major) {
        return formatDegreeMajor(studentInfo) === normalizedValue;

    } else if (normalizedKey === ScribeCode.Concentration) {
        return studentInfo.conc === normalizedValue;

        // (BIOL. @W IS PASSED)
    } else if (normalizedValue === 'PASSED') {
        const requiredCourse: ScribedCourse = normalizedKey.split(/\s+/) as ScribedCourse;

        const qualifying = getQualifyingEnrollments({
            except_courses: [],
            list_type: 'OR',
            scribed_courses: [ [ requiredCourse ] ]}, studentInfo.enrollments);
        return qualifying.length ? `true: ${qualifying.map(o => parseClassFromStudentEnrollmentHistory(o))}` : false;
    }

    return false;
}

export function evaluateConditions(input: string, studentInfo: StudentInfoWithDegree) {
    const conditions: EvaluatedExpression[] = parseConditionStr(input);
    const results = conditions.map(condition => evaluateExpression(condition, studentInfo));

    const anyFalse = results.find(o => !o);
    return anyFalse ? false : results.join('\n');
}

export function parseConditionOperator(input: string): ConditionOperator {
    const regex = /(?:\s*(>|>=|<|<=|=|<>|\bis\b|\bisnt\b|\bwas\b)\s*)/i;
    const matches = input.match(regex);
    if (!matches) {
        throw new Error('parseConditionOperator unable to parse input: ' + input);
    }
    return matches[1] as ConditionOperator;
}
export function parseConditionValue(input: string) {
    if (input.match(/\bY\b/i)) return true;
    if (input.match(/\bN\b/i)) return false;

    return input.toUpperCase();
}

export function parseConditionStr(rawInput: string): EvaluatedExpression[] {
    let trimmed = rawInput.trim();
    if (rawInput[0] === '(' && rawInput[rawInput.length - 1] === ')') {
        trimmed = trimmed.slice(1, -1);
    }

    const conditions = trimmed.split(/\band\b/ig);

    return conditions.map(condition => {
        const operator = parseConditionOperator(trimmed);
        const split = trimmed.split(operator);
        if (split.length !== 2) {
            throw new Error('unhandled conditionStr. expected exactly two components after splitting on \'=\'. instead received: ' + rawInput);
        }
        return {
            key: split[0].trim(),
            value: split[1].trim(),
            operator,
        };
    });
}

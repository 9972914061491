import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const PageContainerStyle = styled.div`
   //scrollbar-gutter: stable;
    overflow-y: auto;
    margin: auto;
    ${tw`mt-8 px-4 py-4`}
    .header {
        ${tw`mb-6 pb-2`}
        border-bottom: 1px solid grey;
        h3 {
            ${tw`text-2xl`}
        }
    }

`;

export const StudentFormStyle = styled.form`
    ${tw`mb-8`}
    
    
    input {
        -webkit-appearance: none;
        border: 1px solid grey;
        border-radius: 3px;
        padding-left: 8px;
        padding-right: 8px;
        ${tw`text-lg py-2 px-3`}
    }
    
    button {
        ${tw`px-8 text-lg`}
    }
    
    .field {
        ${tw`mb-4`}
        label {
            ${tw`font-medium mr-2`}
        }
    }
    .details-container {
        ${tw`flex flex-row`}
        > * {
            ${tw`w-full`}
        }
    }

`;

export const DegreeRequirementsStyle = styled.div`
    ${tw`mb-8`}
`;


export const AuditedBlockStyle = styled.div`
    
    ${tw`px-4 py-4 border border-solid border-gray-300`}
    
`;


interface AuditedRuleEntryStyleProps {
    isRoot: boolean;
}


export const AuditedRuleEntryStyle = styled.div<AuditedRuleEntryStyleProps>`
    ${props => props.isRoot ?
        `
        border-bottom: 1px solid grey;
        margin-bottom: 10px;
        
        `
        : ''    
    }
    ${tw`mb-8`}
`;

export const EnrollmentHistoryContainerStyle = styled.div`
    ${tw`mb-8`}
    table {
        ${tw`w-full`}
    }
    
`;

import { createGlobalStyle } from 'styled-components/macro';
import tw from 'twin.macro';

import brandonLight from '@fonts/Brandon_Grotesque/Brandon_light.otf';
import brandonRegular from '@fonts/Brandon_Grotesque/Brandon_reg.otf';
import brandonMedium from '@fonts/Brandon_Grotesque/Brandon_med.otf';
import brandonBlack from '@fonts/Brandon_Grotesque/Brandon_blk.otf';
import brandonBold from '@fonts/Brandon_Grotesque/Brandon_bld.otf';

export default createGlobalStyle`
    @font-face{
        font-family: 'Brandon';
        src: url(${brandonLight});
        font-display:auto; font-style:normal;
        font-weight: 300;
    }
    @font-face{
        font-family: 'Brandon';
        src: url(${brandonRegular});
        font-display:auto; font-style:normal;
        font-weight: 400;
    }
    @font-face{
        font-family: 'Brandon';
        src: url(${brandonMedium});
        font-display:auto; font-style:normal;
        font-weight: 500;
    }
    @font-face{
        font-family: 'Brandon';
        src: url(${brandonBold});
        font-display:auto; font-style:normal;
        font-weight: 700;
    }
    @font-face{
        font-family: 'Brandon';
        src: url(${brandonBlack});
        font-display:auto; font-style:normal;
        font-weight: 900;
    }

    html {
        box-sizing: border-box;
            min-height: 100vh;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }
    html,
    body {
            //scrollbar-gutter: stable;
            //overflow-y: scroll;
    }

    body {
        margin: 0;
        font-family: 'Brandon';
        margin: auto;
        //width: 100%;
        height: 100%;
        position: relative;
    }
    h1, h2, h3, h4, h5, h6, p, span, i {
        font-family: 'Brandon';
        margin: 0;
        ${tw`text-dark`}
    }
    
    h1 {${tw`text-3xl`}}
    h2 {${tw`text-2xl`}}
    h3 {${tw`text-xl`}}
    h4 {${tw`text-lg`}}
    

    *:focus {
        outline: none;
        backface-visibility: hidden;
    }
    
    table {
        border-collapse: collapse;
    }
    
    // for some reason chrome active links are turned Red. not sure if due to some library we're using.
    a:-webkit-any-link:active {
        color: inherit;
    }

    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    b {
        font-weight: 500;
    }
`;

import _ from 'lodash';
import {AuditedRequirementBlock, AuditedRuleEntry, AuditStatus, StudentInfoWithDegree} from '@/degrees/audit/types';
import {RequirementBlock} from '@/degrees/types';
import auditRuleEntry from '@/degrees/audit/auditRuleEntry';

export default async function auditRequirementBlock(requirementBlock: RequirementBlock, studentInfo: StudentInfoWithDegree): Promise<AuditedRequirementBlock> {
    console.log('auditRequirementBlock requirementBlock: ', requirementBlock);

    const { parseTree: { header_list, body_list } } = requirementBlock;

    // TODO: parse/audit header_list


    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
    };

    const auditedBodyList: AuditedRuleEntry[] = [];
    for (let i = 0; i < body_list.length; i++) {
        const audited = await auditRuleEntry(body_list[i], studentInfo);
        if ('block' in audited && !audited.block.populatedBlock) {
            console.error('audited block entry but did not include populated block');
            throw new Error('audited block entry but did not include populated block');
        }
        console.log('auditRequirementBlock audited: ', audited);
        auditedBodyList.push(audited);

        if (!audited.completed) {
            auditStatus.completed = false;
            auditStatus.message = 'One or more rules unmet';
        }
    }

    console.log('auditRequirementBlock auditedBodyList: ', auditedBodyList);
    const auditedBlock = _.cloneDeep(requirementBlock);

    auditedBlock.parseTree.body_list = auditedBodyList;
    return {
        ...auditedBlock,
        ...auditStatus,
    };
}

import {RuleSubset, SubsetRuleEntry} from '@/degrees/rules';
import {StudentInfo} from '@/api/types';
import _ from 'lodash';
import {AuditedRuleEntry, AuditStatus, RuleEntryAuditor} from '@/degrees/audit/types';

export default async function auditSubsetRule(rule: RuleSubset, studentInfo: StudentInfo, auditRuleEntry: RuleEntryAuditor): Promise<AuditedRuleEntry> {

    const requirements = rule.requirements;

    const auditedReqs: SubsetRuleEntry[] = [];

    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
    };

    for (let i = 0; i < requirements.length; i++) {
        const audited = await auditRuleEntry(requirements[i], studentInfo) as AuditedRuleEntry<SubsetRuleEntry>;
        auditedReqs.push(audited);

        if (!audited.completed) {
            auditStatus.completed = false;
            auditStatus.message = 'One or more requirements in sub block not met.';
        }
    }

    return {
        subset: {
            ...rule,
            requirements: auditedReqs
        },
        ...auditStatus
    };
}

import React, { useMemo } from 'react';
import RuleCheckbox from '@/components/degree/RuleCheckbox';
import RuleStatusMessage from '@/components/degree/RuleStatusMessage';
import { StudentEnrollment } from '@/api/types';
import _ from 'lodash';
import {
    parseClassFromStudentEnrollmentHistory,
    parseCourseSubjectAndNumber,
    parseGradeOrInProgress
} from '@/api/transformers';
import tw from 'twin.macro';
import {AuditedRequirementBlock, AuditedRuleEntry} from '@/degrees/audit/types';

interface Props {
    entry: AuditedRuleEntry | AuditedRequirementBlock
}

const renderTransferrableClass = (enrollment: StudentEnrollment) => {
    if (enrollment.transferredAs?.length) {
        return (
          <>
              <span css={[tw`mx-1`]}>{enrollment.transferredAs.map(c => parseCourseSubjectAndNumber(c)).join(', ')}</span>
              <span css={[tw`mx-1`]}>Transferred from [{enrollment.institution}] {parseClassFromStudentEnrollmentHistory(enrollment)}</span>

          </>
        );
    } else {
        return <span css={[tw`mx-1`]}>{parseClassFromStudentEnrollmentHistory(enrollment)}</span>;
    }
};

export default ({ entry }: Props) => {
    const enrollments = useMemo<StudentEnrollment[]>(() => {
        if (entry.enrollments) {
            return _.concat(entry.enrollments);
        }
        return [];
    }, [ entry ]);

    return (
        <div css={[tw`flex flex-row`]}>
            <RuleCheckbox completed={!!entry.completed}/>
            <div>
                <RuleStatusMessage completed={!!entry.completed} message={entry.message || ''}/>
                {
                    enrollments.map((o, i) => {
                        return (
                            <p key={i} css={[tw`text-xs`]}>
                                {/* <span css={[tw`mx-1`]}>{parseClassFromStudentEnrollmentHistory(o)}</span> */}
                                {renderTransferrableClass(o)}

                                <span css={[tw`mx-1`]}>Grade: {parseGradeOrInProgress(o)}</span>
                                <span css={[tw`mx-1`]}>Units: {o.earnCredit === 'Y' && o.unitsEarned ? o.unitsEarned.toFixed(2) : '' }</span>
                            </p>
                        );

                    })
                }
            </div>
        </div>
    );
};
